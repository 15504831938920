import Config from "@/vendor/config"
import Request from "@/api/request.js"

const SignSignIn = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/sign/signIn',params)
}

const SignCalendar = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/sign/calendar',params)
}

const getTodayOnlineTask = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/list')
}

const taskDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/detail',params)
}

const hasSigned = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/sign/hasSigned',params)
}

const offlineTaskGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/offlineTaskGroup',params)
}

const offlineTaskDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/offlineTaskDetail',params)
}

const offlineTaskAdd = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/offlineTaskAdd',params)
}

const complete = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/complete',params)
}

// 是否可以做任务
const isCanComplete = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/task/isCanComplete',params)
}

export default {
	SignSignIn,
	SignCalendar,
	getTodayOnlineTask,
	taskDetail,
	hasSigned,
	offlineTaskGroup,
	offlineTaskDetail,
	offlineTaskAdd,
	complete,
	isCanComplete
}
