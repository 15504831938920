<template>
	<div :class="['task-list', className('task-list-')]">
		<div class="task-item" v-for="vo in taskList">
			<div class="task-item-left">
				<div class="task-item-title">{{ vo.title }}</div>
				<div class="task-item-tags">
					<div class="task-item-tag task-item-tag-blue" v-if="vo.type == 1">分享任务</div>
					<div class="task-item-tag task-item-tag-blue" v-if="vo.type == 2">分享链接</div>
					<div class="task-item-tag task-item-tag-blue" v-if="vo.type == 3">阅读任务</div>
					<div class="task-item-tag task-item-tag-red">+{{ vo.love_value }}爱心值</div>
				</div>
			</div>
			<div class="task-item-right" >
				<van-button v-if="vo.is_complete == 0" round type="info" :color="themeColor()" @click="$router.push({ name: 'OnlineTaskDetail', params: { id: vo.id } })">去做任务</van-button>
				<van-button v-if="vo.is_complete == 1" round type="info" disabled>已完成</van-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['themeColor', 'className', 'districtType'],
	props: {
		taskList: {
			default: []
		}
	}
};
</script>

<style lang="less" scoped>
@import '~@/assets/less/style.less';

.task-list {
	padding: 10px;
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
	background-color: #fff;

	.task-item {
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: 12px;
		padding-bottom: 10px;
		.border-bottom();

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;

			&:after {
				display: none;
			}
		}

		.task-item-left {
			flex: 1;

			.task-item-title {
				font-size: 18px;
				line-height: 26px;
				padding-bottom: 4px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.task-item-tags {
				display: flex;

				.task-item-tag {
					padding: 2px 7px;
					border-radius: 10px;
					font-size: 12px;
					margin-right: 10px;
				}
			}
		}

		.task-item-right {
			width: 100px;

			.van-button {
				width: 100%;
				height: 38px;

				&[disabled] {
					opacity: 1;
					background-color: #ddd !important;
					color: #aaa !important;
					border-color: #ddd !important;
				}
			}
		}
	}
}

.task-list-city {
	.task-item-tag-blue {
		background-color: rgba(51, 119, 255, 0.1);
		color: #3377ff;
	}

	.task-item-tag-red {
		background-color: rgba(255, 105, 105, 0.1);
		color: #ff6969;
	}
}

.task-list-district {
	.task-item-tag-blue {
		background-color: rgba(255, 105, 105, 0.1);
		color: #ff6969;
	}

	.task-item-tag-red {
		background-color: rgba(255, 105, 105, 0.1);
		color: #ff6969;
	}
}
</style>
