<template>
	<div class="task">
		<w-navTab titleText="任务中心"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="nav-picture">
			<div class="nav-midle">
				<div class="nav-midle-conner">
					<div class="nav-midle-conner-box">
						<div class="title">+{{ loveValue == '' ? '0' : loveValue }}</div>
						<div class="text">{{ taskShow == false ? '未签到' : '今日已签到' }}</div>
					</div>
				</div>
			</div>
			<div class="nav-bottom-text">您的爱心积分：{{ totalIntegral }}</div>
		</div>
		<div class="head">
			<div class="head-top">
				<div class="head-top-left">断签重置积分</div>
				<div class="head-top-right" @click="$router.push({ name: 'IntegralRule' })">积分规则？</div>
			</div>
			<div class="head-middle">
				<div class="head-middle-item" v-for="(item, key) in signCalendar">
					<!-- 未签到 -->
					<div class="middle-item-bord" v-if="item.is_sign == false && item.is_before == true">
						<div class="middle-item-bord-text">{{ item.love }}</div>
					</div>
					<!-- 已签到 -->
					<div class="middle-item-bord-centre" v-if="item.is_sign == true">
						<div class="middle-item-bord-text-centre"><van-icon name="success" color="#fff" /></div>
					</div>
					<!-- 时间未到 -->
					<div class="middle-item-bord-right" v-if="item.is_sign == false && item.is_before == false">
						<div class="middle-item-bord-text-right">+{{ item.love }}</div>
					</div>
				</div>
			</div>
			<div class="head-middle-box">
				<div class="head-middle-text" v-for="item in onlineTask">{{ item.name }}</div>
			</div>
			<div class="head-bottom" @click="onTask">
				<van-button type="primary" round color="#3377FF" block v-if="taskShow == false && districtType() == 1">点击签到</van-button>
				<van-button type="primary" round color="#3377FF" block disabled v-if="taskShow == true && districtType() == 1">今日已签到</van-button>
				<van-button type="primary" round color="#ff6969" block v-if="taskShow == false && districtType() == 2">点击签到</van-button>
				<van-button type="primary" round color="#ff6969" block disabled v-if="taskShow == true && districtType() == 2">今日已签到</van-button>
			</div>
		</div>
		<div class="middle">
			<div class="middle-top" @click="$router.push({ name: 'Redeem' })"><img src="@/assets/img/test206.png" alt="" /></div>
			<div class="middle-nav"><w-title title="每日任务"></w-title></div>
			<div class="middle-conner"><online-task-list :taskList="onlineTask"></online-task-list></div>
			<div class="middle-nav"><w-title title="线下任务"></w-title></div>
			<div class="middle-bottom">
				<div class="middle-bottom-item" v-for="item in offline" @click="$router.push({ name: 'OfflineTaskSubmit', params: { id: item.id } })">
					<div class="item-left">
						<div class="item-title">{{ item.name }}</div>
						<div class="item-text">+{{ item.love_value }}爱心值</div>
					</div>
					<div class="item-right" v-if="districtType() == 1">上报任务</div>
					<div class="item-right-color" v-if="districtType() == 2">上报任务</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Task from '@/api/task';
import Vant from '@/vendor/vant';
import WTitle from '@/components/Title';
import LoadingPage from '@/components/LoadingPage';
import OnlineTaskList from '@/components/OnlineTaskList';

export default {
	inject: ['themeColor', 'className', 'districtType'],
	name: 'Task',
	data() {
		return {
			loadingPage: true,
			onlineTask: [],
			totalIntegral: '',
			signCalendar: [],
			isSigned: '',
			offline: '',
			signSignIn: '',
			loveValue: '',
			taskShow: false
		};
	},
	created() {
		this.onHasSigned();
		this.onLoad();
		Task.offlineTaskGroup()
			.then(res => {
				this.offline = res.data;
			})
			.catch(err => {
				Vant.Toast.fail(err.msg);
			});
	},
	methods: {
		onHasSigned() {
			Task.hasSigned()
				.then(res => {
					this.isSigned = res.data.is_signed;
					this.loveValue = res.data.love_value;
					if (this.isSigned == 0) {
						this.taskShow = false;
					} else {
						this.taskShow = true;
					}
				})
				.catch(err => {
					Vant.Toast.fail(err.msg);
				});
		},
		onLoad() {
			let calendarApi = Task.SignCalendar();
			let onlineTaskApi = Task.getTodayOnlineTask();

			Promise.all([calendarApi, onlineTaskApi])
				.then(result => {
					let [calendar, onlineTask] = result;

					this.totalIntegral = calendar.data.total_integral;
					this.signCalendar = calendar.data.list;
					this.onlineTask = onlineTask.data;

					this.loadingPage = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		onTask() {
			Vant.Toast({
				message: '签到中',
				duration: 0
			});
			Task.SignSignIn()
				.then(res => {
					Vant.Toast.success('签到成功');
					this.taskShow = !this.taskShow;
					this.signSignIn = res.data;
					this.onLoad();
					this.onHasSigned();
				})
				.catch(err => {
					if (this.isSigned == true) {
						Vant.Toast('您今天已经签到过了哦！');
					} else {
						Vant.Toast.fail(err.msg);
					}
				});
		}
	},
	components: {
		WTitle,
		LoadingPage,
		OnlineTaskList
	}
};
</script>

<style lang="less" scoped>
.task.city {
	.nav-picture {
		background: url(../../assets/img/test205.png);
		.nav-midle-conner-box {
			.title {
				color: #3377ff;
			}
			.text {
				color: #3377ff;
			}
		}
	}
	.head {
		.head-top-right {
			color: #3377ff;
		}
		.head-bottom {
			.head-bottom-text {
				background-color: #3377ff;
			}
		}
	}
}

.task.district {
	.nav-picture {
		background: url(../../assets/img/mr.png);
		.nav-midle-conner-box {
			.title {
				color: #ff6969;
			}
			.text {
				color: #ff6969;
			}
		}
	}
	.head {
		.head-top-right {
			color: #ff6969;
		}
		.head-bottom {
			.head-bottom-text {
				background-color: #ff6969;
			}
		}
	}
}

.task {
	.nav-picture {
		width: 100%;
		height: 200px;
		background-size: 100%;
		padding-top: 35px;
		box-sizing: border-box;
		.nav-midle {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin-left: 50%;
			transform: translateX(-50%);
			background-color: rgba(255, 255, 255, 0.2);
			box-sizing: border-box;
			padding-top: 5px;
			.nav-midle-conner {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				margin-left: 50%;
				transform: translateX(-50%);
				background-color: rgba(255, 255, 255, 0.5);
				box-sizing: border-box;
				padding-top: 5px;
				.nav-midle-conner-box {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					padding-top: 12px;
					background-color: #fff;
					box-sizing: border-box;
					margin-left: 50%;
					transform: translateX(-50%);
					text-align: center;
					.title {
						font-size: 32px;
						line-height: 32px;
					}
					.text {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
		.nav-bottom-text {
			margin-top: 10px;
			font-size: 15px;
			line-height: 26px;
			color: #ffffff;
			text-align: center;
		}
	}

	.head {
		padding: 20px 10px;
		background-color: #fff;
		margin-top: -15px;
		border-radius: 15px 15px 0px 0px;
		.head-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.head-top-left {
				font-size: 14px;
				line-height: 26px;
				color: #666;
			}
			.head-top-right {
				font-size: 14px;
				line-height: 26px;
			}
		}
		.head-middle {
			margin-top: 17px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.head-middle-item {
				width: 33px;
				height: 33px;
				border-radius: 50%;
				border: 1px solid #ffb23f;
				// 未签到
				.middle-item-bord {
					width: 27px;
					height: 27px;
					border-radius: 50%;
					background-color: #ddd;
					margin: 3px;
					.middle-item-bord-text {
						font-size: 14px;
						line-height: 26px;
						text-align: center;
						color: #aaa;
					}
				}
				// 成功签到
				.middle-item-bord-centre {
					width: 27px;
					height: 27px;
					border-radius: 50%;
					background: #ffb23f;
					margin: 3px;
					.middle-item-bord-text-centre {
						text-align: center;
						padding-top: 6.5px;
					}
				}
				// 将要签到
				.middle-item-bord-right {
					width: 27px;
					height: 27px;
					border-radius: 50%;
					margin: 3px;
					.middle-item-bord-text-right {
						font-weight: bold;
						font-size: 14px;
						line-height: 26px;
						text-align: center;
						color: #ffb23f;
					}
				}
			}
		}
		.head-middle-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 4px 5px 20px 5px;
			.head-middle-text {
				font-size: 12px;
				line-height: 20px;
				color: #666;
			}
		}

		.head-bottom {
			padding: 0 70px;
			.van-button {
				font-size: 16px;
			}
		}
	}

	.middle {
		padding: 10px;
		padding-bottom: 20px;
		.middle-top {
			width: 100%;
			height: 68px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.middle-nav {
			margin: 10px 0 12px;
		}
		.middle-conner {
			border-radius: 8px;
			overflow: hidden;
		}
		.middle-bottom {
			padding: 0 10px;
			background: #fff;
			border-radius: 8px;
			.middle-bottom-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 0.5px solid #f2f2f2;
				padding: 10px 0;
				.item-left {
					.item-title {
						width: 200px;
						min-width: 200px;
						font-size: 18px;
						line-height: 26px;
						color: #333;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.item-text {
						width: 70px;
						font-size: 12px;
						color: #ff6969;
						margin-top: 6px;
						line-height: 18px;
						background: rgba(255, 105, 105, 0.1);
						border-radius: 100px;
						text-align: center;
						padding: 0 4px;
					}
				}
				.item-right {
					padding: 8px 22px;
					font-size: 14px;
					line-height: 20px;
					text-align: center;
					color: #ffffff;
					background: #3377ff;
					border-radius: 100px;
				}
				.item-right-color {
					padding: 8px 22px;
					font-size: 14px;
					line-height: 20px;
					text-align: center;
					color: #ffffff;
					background: #ff6969;
					border-radius: 100px;
				}
			}
			.middle-bottom-item:last-child {
				border: 0;
			}
		}
	}
}
</style>
